class Packet {
  constructor () {
    this.cmd = null
    this.retain = false
    this.qos = 0
    this.dup = false
    this.length = -1
    this.topic = null
    this.payload = null
  }
}

module.exports = Packet
